import { SiteContext } from "@/contexts/SiteContext";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef, useState } from "react";
import { getProductReviews } from "./queries/product";
import { cn } from ".";

export function classNames(...classes) {
    return cn(classes)
}

export function getEndpointUrl(link) {
    let endpoint = link.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE_URL, "")
    endpoint = endpoint.replace('https://cart.gourmetbasket.com.au/', "")
    endpoint = endpoint.replace('https://gb-headless-v2.blz.onl/', "")
    return `${endpoint}`
}

export function replaceBackendUrlToFrontEndUrl(str) {
    return str.replaceAll(process.env.NEXT_PUBLIC_WORDPRESS_SITE_URL, process.env.NEXT_PUBLIC_FRONTEND_SITE_URL)
}

export function seoUrlParser(data) {

    data = data.replace(
        new RegExp(process.env.NEXT_PUBLIC_WORDPRESS_SITE_URL, 'g'),
        process.env.NEXT_PUBLIC_FRONTEND_SITE_URL
    );

    data = data.replace(
        new RegExp('https://cart.gourmetbasket.com.au', 'g'),
        process.env.NEXT_PUBLIC_FRONTEND_SITE_URL
    );

    data = data.replace(
        new RegExp(`${process.env.NEXT_PUBLIC_FRONTEND_SITE_URL}/wp-content`, 'g'),
        'https://cart.gourmetbasket.com.au/wp-content'
    );


    data = data.replace(
        new RegExp('https://gb-headless-v2.blz.onl', 'g'),
        process.env.NEXT_PUBLIC_FRONTEND_SITE_URL
    );
    data = data.replace(
        new RegExp(`${process.env.NEXT_PUBLIC_FRONTEND_SITE_URL}/wp-content`, 'g'),
        'https://gb-headless-v2.blz.onl/wp-content'
    );

    data = data.replace(
        new RegExp('https://gb-headless.blz.onl', 'g'),
        process.env.NEXT_PUBLIC_FRONTEND_SITE_URL
    );
    data = data.replace(
        new RegExp(`${process.env.NEXT_PUBLIC_FRONTEND_SITE_URL}/wp-content`, 'g'),
        'https://gb-headless.blz.onl/wp-content'
    );

    return data;
}

export const stripTrailingSlash = (str) => {
    return str.endsWith('/') ?
        str.slice(0, -1) :
        str;
}

export const uniqueArrayOfObject = (array, keyToBeUnique) => {
    var flags = {};
    var uniqueData = array.filter(function (item) {
        if (item.grouped) {
            if (flags[item[keyToBeUnique]]) {
                return false;
            }
            flags[item[keyToBeUnique]] = true;
        }

        return true;
    });

    return uniqueData
}

export const getGiftCards = (array) => {
    var data = array.filter(function (item) {
        if (item.name.toLowerCase() == 'gift styling') {
            return true;
        }
        return false;
    });

    return data[0] ? data[0] : null;
}



export const usePreviousRoute = () => {

    const { asPath } = useRouter()
    const ref = useRef()

    useEffect(() => { ref.current = asPath }, [asPath])

    return ref.current
}

export const useFetchReviews = (sku, page) => {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (
            async function () {
                try {
                    setLoading(true)
                    const response = await getProductReviews(sku, page)
                    setData(response.data)
                } catch (err) {
                    setError(err)
                } finally {
                    setLoading(false)
                }
            }
        )()
    }, [sku, page])

    return { data, error, loading }
}

export const isDevEnvironment = process && process.env.NODE_ENV === "development"; 