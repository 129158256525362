import { gql } from "@apollo/client";
import client from '@/utils/ApolloClient'
import { PRODUCT_REVIEWS_ENDPOINT } from "@/utils/constants/endpoints";
import axios from 'axios';

export const PRODUCT_FRAGMENTS = `
id
productId: databaseId
description,
averageRating
slug
link
permalink:link
image {
	id
	uri
	title
	srcSet
	sourceUrl
	altText
}
... on SimpleProduct {
	price(format: RAW)
	regularPrice(format: RAW)
	sku
	name
	onSale
	stockQuantity
	stockStatus
}
`

export const PRODUCT_SEO_FRAGMENTS = `
... on SimpleProduct {
	seo {
		fullHead
	}
}
`

export const PRODUCT_BY_IDS = gql`
query ProductsByIds($ids: [Int]) {
	products(where: {include: $ids, status: "publish"}) {
		nodes {
			${PRODUCT_FRAGMENTS}
		}
	}
}
`

export const RECENTLY_VIEWED = PRODUCT_BY_IDS

export const PRODUCT_STOCK_STATUS_BY_ID = gql`
query ProductStockStatusById($productId: ID!) {
	product(id: $productId, idType: DATABASE_ID) {
		... on SimpleProduct {
			stockQuantity
			stockStatus
		}
	}
}
`;
export const PRODUCT_BY_SLUG_QUERY = gql`
query Product($slug: ID!) {
	product(id: $slug, idType: SLUG) {
		${PRODUCT_FRAGMENTS}
		${PRODUCT_SEO_FRAGMENTS}
		galleryImages {
			nodes {
				id
				title
				altText
				sourceUrl
			}
		}
		productIngredients(first: 50) {
			nodes {
				description
				name
				imageSourceUrl
				slug
			}
		}
		productCategories {
			nodes {
				name
        		uri
			}
		}
		productTags {
			nodes {
				name
        		uri
			}
		}
		productTaxonomyFavourites {
			nodes {
				name
        		uri
			}
		}
		productTaxonomyOccasions {
			nodes {
				name
        		uri
			}
		}
		productTaxonomyShopBys {
			nodes {
				name
        		uri
			}
		}
		productTaxonomyTypes {
			nodes {
				name
        		uri
			}
		}
	}
}
`;

export const PRODUCT_SLUGS = gql`
query Products {
	products(
		first: 5000
		where: {status: "publish", stockStatus: IN_STOCK, categoryIdIn: [501, 407]}
	) {
		nodes {
			link
		}
	}
}
`;

const ADDON_GROUPY_FRAGMENT = `
addonsGroupy(product_id: $productId) {
	group_image
	group_name
	addons {
		description
		field_name
		image
		name
		options {
			img
			label
			inactive
			price
			sku
			field_name
			label_slug
			weight
		}
	}
}
`

const GIFT_STYLE_FRAGMENT = `
addonsGiftStyle(product_id: $productId) {
	img
	label
	inactive
	price
	sku
	field_name
	label_slug
}
`

export const GIFT_STYLES_BY_PRODUCT_ID = gql`
query ProductAddOnsQuery($productId: Int) {${GIFT_STYLE_FRAGMENT}}
`;

export const PRODUCT_ADDONS_BY_ID = gql`
query ProductAddOnsQuery($productId: Int) {
	${GIFT_STYLE_FRAGMENT}
	${ADDON_GROUPY_FRAGMENT}
}
`;

export const CUSTOM_ADD_TO_CART = gql`
mutation customAddToCartMutation($productId:Int!, $extraData:String!, $quantity:Int, $cartKey: String) {
	addtocartWithAddons(
		input: {productId: $productId, extraData: $extraData, quantity: $quantity, cartKey: $cartKey}
	) {
		cart_key
		raw_data
	}
}
`;

export const ADD_TO_CART = gql`
mutation addToCart($input: AddToCartInput!) {
	addToCart(input: $input) {
		cartItem {
			extraData {
				id
				key
				value
			}
			product {
				node {
					id
					name
					type
					slug
					image {
						altText
						id
						sourceUrl
					}
				}
			}
			quantity
			subtotal
			subtotalTax
			tax
			total
		}
	}
}
`;


export const getProductPageDataBySlug = async (slug) => {
	return await client.query(({
		query: PRODUCT_BY_SLUG_QUERY,
		variables: { slug }
	}));
}

export const getProductReviews = async (sku, page = 1) => {
	return await axios.get(PRODUCT_REVIEWS_ENDPOINT + sku + '&page=' + page);
}

export const getProductAddOnsByProductId = async (productId) => {
	return await client.query(({
		query: PRODUCT_ADDONS_BY_ID,
		variables: { productId }
	}));
}


