import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import TS_CONFIG from './config';

let specificSearchParameters = {};
specificSearchParameters[TS_CONFIG.collectionNames.product] = {
    query_by: 'name,description',
    filter_by: 'status:=[`publish`]',
};

specificSearchParameters[TS_CONFIG.collectionNames.taxonomy] = {
    query_by: 'bannerText,description,name',
    filter_by:
        'type:=[`product_cat`,`product_tag`,`occasion`,`product-type`,`favourite`,`shop-by`]',
};

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: TS_CONFIG.server,
    collectionSpecificSearchParameters: specificSearchParameters,
});

export default typesenseInstantsearchAdapter;
