import React from 'react';
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch-hooks-web';

export function CustomSearchBox({ onFocus, setSearchTerm }) {
    const { query, refine } = useSearchBox();

    const onSearchTermChange = (e: any) => {
        setSearchTerm(() => {
            refine(e.target.value);
            return e.target.value;
        });
    };
    return (
        <>
            <input
                placeholder="Search"
                onFocus={onFocus}
                className="border-0 ring-0 ring-transparent focus:ring-brand-primary focus:ring-2 focus:border-brand-primary bg-brand-primary-dark w-full pl-2  uppercase outline-none  ring-offset-brand-primary text-[#af8c5a] placeholder-[#af8c5a] font-montserrat-500 text-sm py-1 "
                onChange={onSearchTermChange}
            />
        </>
    );
}
